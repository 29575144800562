<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['substanceAllergies'])">
      <multi-answer-question
        title="label.substanceAllergies"
        :value="substanceAllergies"
        :other-value="otherSubstanceAllergies"
        :options="$options.responseOptions"
        @input="onFieldChange('substanceAllergies', $event)"
        @other-input="onFieldChange('otherSubstanceAllergies', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import {
  SUBSTANCE_ALLERGY,
  OTHER_VALUE_INCLUSIVE_BINDINGS
} from '@/modules/questionnaire/api/constants';

const RESPONSE_OPTIONS = [
  { value: SUBSTANCE_ALLERGY.PERFUME, text: 'substanceAllergy.perfume' },
  { value: SUBSTANCE_ALLERGY.COSMETICS, text: 'substanceAllergy.cosmetics' },
  { value: SUBSTANCE_ALLERGY.NICKEL, text: 'substanceAllergy.nickel' },
  { value: SUBSTANCE_ALLERGY.GOLD, text: 'substanceAllergy.gold' },
  { value: SUBSTANCE_ALLERGY.CHROMIUM, text: 'substanceAllergy.chromium' },
  { value: SUBSTANCE_ALLERGY.COBALT, text: 'substanceAllergy.cobalt' },
  { value: SUBSTANCE_ALLERGY.FORMALDEHYDE, text: 'substanceAllergy.formaldehyde' },
  { value: SUBSTANCE_ALLERGY.FUNGICIDE, text: 'substanceAllergy.fungicide' },
  { value: SUBSTANCE_ALLERGY.LATEX, text: 'substanceAllergy.latex' },
  {
    value: SUBSTANCE_ALLERGY.DIMETHYLAMINOPROPYLAMINE,
    text: 'substanceAllergy.dimethylaminopropylamine'
  },
  { value: SUBSTANCE_ALLERGY.PARAPHENYLENEDIAMINE, text: 'substanceAllergy.paraphenylenediamine' },
  { ...OTHER_VALUE_INCLUSIVE_BINDINGS, text: 'substanceAllergy.other' }
];

export default {
  name: 'SubstanceAllergiesTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion },
  responseOptions: RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    substanceAllergies: {
      type: Array,
      default: () => []
    },
    otherSubstanceAllergies: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
